import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import { getReference } from "../../services/reference_service";

function About() {
    const [references, setReferences] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        //const result = await getReference();
        //setReferences(result);
    }
    const deleteCard = async (id) => {
        setReferences(references.filter(reference => reference.id !== id));
    }

    return (
        <>
            <h1 className="text-3xl font-bold text-center p-5">Hakkımızda</h1>

            <div className="flex flex-col items-center p-8 md:flex-row md:justify-center">
                <div className="p-10 bg-white border border-gray-200 rounded-b-lg md:rounded-l-none md:rounded-r-lg shadow hover:bg-orange-50 light:bg-gray-800 light:border-gray-700 light:hover:bg-gray-700 flex flex-col md:flex-row items-center">
                    <img className="object-cover w-full h-full md:w-160 md:h-80 rounded-t-lg md:rounded-none md:rounded-l-lg mb-4 md:mb-0" src="https://4-hontario.ca/wp-content/uploads/2020/09/Woodworking-L2_Main-Image-1920x1080.jpeg" alt="Woodworking project" />
                    <p className="text-sm md:text-base lg:text-lg text-gray-700 dark:text-gray-600 mb-4 md:ml-4 text-center">
                        1980 yılında Ömer Alımlı tarafından kurulan mobilya ve ahşap tasarım atölyemiz, yılların verdiği tecrübe ve özveriyle müşterilerimize benzersiz tasarımlar sunmaktadır. Tasarımlarımızda işlevsellik, estetik ve dayanıklılık unsurlarını bir araya getirerek yaşam alanlarınıza değer katmayı hedefliyoruz.
                        Atölyemizde, uzman marangozlarımız ve tasarımcılarımızla birlikte her aşamada özenle çalışıyoruz. Her bir ürünümüz, doğal ve sağlıklı malzemeler kullanılarak özenle el işçiliğiyle üretilir. Mobilya ve ahşap tasarımlarımızda modern çizgileri geleneksel ustalıkla birleştirerek, sizlere şık ve özgün seçenekler sunuyoruz.
                        Müşteri memnuniyeti bizim için her zaman önceliklidir. Bu nedenle, müşterilerimizin talep ve beklentilerini dikkate alarak özel tasarımlar da yapmaktayız. Yaşam alanlarınıza uygun, fonksiyonel ve estetik açıdan tatmin edici mobilya ve ahşap ürünlerimizle, ev, okul, hastane ve bunlar gibi KURUMSAL iş yerlerinizi özgün bir atmosfere dönüştürebilirsiniz.
                        Sürdürülebilirlik ve doğaya saygı da işimizin temel prensiplerindendir. Kullanılan malzemelerin doğal olmasına, üretim süreçlerinin çevreye duyarlı olmasına özen gösteriyoruz. Bu şekilde, hem müşterilerimize kaliteli ürünler sunuyoruz hem de doğal kaynakları koruma misyonumuzu gerçekleştiriyoruz.
                        Bizimle çalışarak, yaşam alanlarınıza değer katan, özgün ve kaliteli mobilya ve ahşap tasarımlarıyla tanışabilirsiniz. Sizleri atölyemize bekliyoruz, hayallerinizdeki mekânı birlikte yaratalım.
                    </p>
                </div>
            </div>

            <h1 className="text-3xl font-bold text-center p-5">Referanslar</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4 md:px-12 lg:px-48 mt-12 mb-12">
                {references.map((reference) => (
                    <Card key={reference.id} reference={reference} onDelete={() => deleteCard(reference.id)} />
                ))}
            </div>
        </>
    );
}

export default About;
