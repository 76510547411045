import axios from 'axios';

export const baseURL = 'https://api.alimlimobilya.com';
//export const baseURL = 'http://localhost:3000';

export default axios.create({ baseURL });

export const URL = {
    // Auth
    login: '/auth/login',
    tokenIsValid: '/auth/tokenisvalid',
    // Category
    getCategories: '/category/get',
    getCategoryById: '/category/getbyid/',
    addCategory: '/category/add',
    updateCategory: '/category/update/',
    deleteCategory: '/category/delete/',
    // Product
    getProduct: '/product/get/',
    getProductById: '/product/getbyid/',
    getProductByCategory: '/product/getproductbycategory/',
    addProduct: '/product/add',
    updateProduct: '/product/update/',
    deleteProduct: '/product/delete/',
    // Reference
    getReferences: '/reference/get',
    getReferenceById: '/reference/getbyid/',
    addReference: '/reference/add',
    updateReference: '/reference/update/',
    deleteReference: '/reference/delete/',
    // Photo
    addPhoto: '/photo/add',
    getPhotoById: '/photo/getbyid/',
    addPhotoFirebase: '/photo/addfirebase',
    // Color
    getColor: '/color/get',
    getColorById: '/color/getbyid/',
    addColor: '/color/add',
    updateColor: '/color/update/',
    deleteColor: '/color/delete/',
    // Color Category
    getColorCategory: '/colorcategory/get',
    getColorCategoryById: '/colorcategory/getbyid/',
    addColorCategory: '/colorcategory/add',
    updateColorCategory: '/colorcategory/update/',
    deleteColorCategory: '/colorcategory/delete/',
    // Project
    getProject: '/project/get',
    getProjectById: '/project/getbyid/',
    addProject: '/project/add',
    updateProject: '/project/update/',
    deleteProject: '/project/delete/',
    // Project Category
    getProjectCategory: '/projectcategory/get',
    getProjectCategoryById: '/projectcategory/getbyid/',
    addProjectCategory: '/projectcategory/add',
    updateProjectCategory: '/projectcategory/update/',
    deleteProjectCategory: '/projectcategory/delete/'
};