import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomDeleteButton from "../../components/button/CustomDeleteButton";
import CustomUpdateButton from "../../components/button/CustomUpdateButton";
import NoPhoto from "../../assets/images/no-photo.png";
import NoProduct from "../../assets/images/product-not-found.png";
import { tokenIsValid } from "../../services/auth_service"
import { deleteColor, getColorByCategory } from "../../services/color_service";

function Color() {
    const { category_name } = useParams();
    const navigate = useNavigate();
    const [colorList, setColorList] = useState([]);
    const [token, setToken] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


    const openModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        getData();
        tokenControl();
    }, []);

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    const getData = async () => {
        const id = category_name.split("=")[1];
        const result = await getColorByCategory(id);
        setColorList(result);
    }

    const colorDelete = async (id) => {
        await deleteColor(id);
        setColorList(prevList => prevList.filter(color => color.id !== id));
    }

    const colorUpdate = (color) => {
        navigate('/color/update/' + color.id, { state: { color } })
    }

    return (
        <>
            {
                (colorList.length !== 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4 px-6 sm:px-12 md:px-16 lg:px-24 mt-12 mb-12">
                        {
                            colorList.map((element) => (
                                <div key={element.id} className="flex flex-col border rounded-lg p-4 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                                    {token ? (
                                        <div className="flex justify-between">
                                            <CustomDeleteButton onClick={() => colorDelete(element.id)} label={'Sil'} />
                                            <CustomUpdateButton onClick={() => colorUpdate(element)} label={'Güncelle'} />
                                        </div>
                                    ) : null}
                                    {
                                        element.photo_url != null ? (
                                            <img className="p-8 rounded-t-lg object-cover w-full h-48 sm:h-64 md:h-48 lg:h-56 xl:h-64" src={element.photo_url} alt={element.photo_url} onClick={() => openModal(element.photo_url)}
                                            />
                                        ) : (
                                            <img className="p-8 rounded-t-lg object-cover w-full h-48 sm:h-64 md:h-48 lg:h-56 xl:h-64" src={NoPhoto} alt={element.photo_url} onClick={() => openModal(element.photo_url)} />
                                        )
                                    }
                                    {isModalOpen && selectedImage && (
                                        <div className="fixed inset-0 flex items-center justify-center z-50">
                                            <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={closeModal}></div>
                                            <div className="relative">
                                                <button className="absolute top-0 right-0 m-2 text-black text-xl" onClick={closeModal}>
                                                    X
                                                </button>
                                                <img
                                                    className="object-contain h-screen w-screen"
                                                    src={selectedImage}
                                                    alt="Selected Color"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex justify-center px-5 pb-5">
                                        <h5 className="flex items-center justify-center text-2xl font-bold tracking-tight text-gray-900">{element.color_name}</h5>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-screen">
                        <img className="max-w-full max-h-full" src={NoProduct} alt="no-product" />
                    </div>
                ))
            }
        </>
    );
};

export default Color;